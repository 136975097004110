<template>
    <div class="columns is-centered">
        <div class="column is-three-quarters-desktop is-full-touch">
            <enso-form class="box form-box has-background-light raises-on-hover"
                       ref="liquidationForm">
                <template #variable_id="props">
                    <form-field has-tooltip-right v-bind="props"
                                @selection="toggleVariable"/>
                </template>
            </enso-form>
        </div>
    </div>
</template>

<script>
import { EnsoForm, FormField } from '@enso-ui/bulma';

export default {
    name: 'Edit',
    components: { EnsoForm, FormField },
    inject: ['route', 'http', 'i18n', 'errorHandler'],
    data() {
        return {
            rules: [],
            ready: false,
        };
    },
    computed: {
        form() {
            return this.ready
                ? this.$refs.liquidationForm
                : {};
        },
    },
    mounted() {
        this.ready = true;
        this.fetchRules();
    },
    methods: {
        toggleVariable(variable) {
            if (!variable) {
                console.error('Variable object is null or undefined');
                return;
            }
            const variableType = variable.type;
            const variablePoints = variable.points;
            let rulePoints;

            if (this.rules && this.rules.length > 0) {
                for (let i = 0; i < this.rules.length; i++) {
                    if (this.rules[i].variable_id === variable.id) {
                        rulePoints = this.rules[i].points;
                        console.log(this.rules[i].variable_id);
                    }
                }
            }

            if (this.$refs.liquidationForm && this.$refs.liquidationForm.field) {
                const variable = this.$refs.liquidationForm.field('variable_id');
                if (variable && variable.meta) {
                    if (variableType === 'liquidated_campaigns') {
                        this.$refs.liquidationForm.field('points').meta.hidden = false;
                        this.$refs.liquidationForm.field('points').value = null;
                    } else if (variableType === 'periodic_indicator') {
                        this.$refs.liquidationForm.field('points').value = rulePoints;
                        this.$refs.liquidationForm.field('points').meta.hidden = true;
                    } else if (variableType === 'points_campaigns') {
                        this.$refs.liquidationForm.field('points').value = variablePoints;
                        this.$refs.liquidationForm.field('points').meta.hidden = true;
                    }
                } else {
                    console.error('Field "variable" does not have a valid meta property');
                }
            } else {
                console.error('liquidationForm or its field method is not defined');
            }
        },
        fetchRules() {
            this.http
                .get(this.route('rule.options'))
                .then(({ data }) => {
                    this.rules = data;
                })
                .catch(this.errorHandler);
        },
    },
};
</script>

<style lang="scss">
</style>
